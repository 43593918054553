$(function () {
	var $carousel = $('.clients, .home, .connection').find('.carousel');
	$carousel.each(function (i) {
		i++;
		var $this = $(this);
		
		var $thisId;
		if (!!$this.attr('id')) {
			$thisId = $this.attr('id');
		}
		else {
			$thisId = 'carousel-' + i;
		}
		var $thisIndicators = $this.find('.carousel-indicators li');
		var $carouselControl = $this.find('.carousel-control');
		$this.attr('id', $thisId);
		$thisIndicators.first().addClass('active');
		$thisIndicators.attr('data-target', '#' + $thisId);
		$thisIndicators.each(function(k) {
			$(this).attr('data-slide-to', k);
			k++;
		});
		$carouselControl.attr('href', '#' + $thisId).addClass('disabled');
		$thisIndicators.addClass('disabled');
		if($this.find('.item').length > 1) {
			$carouselControl.removeClass('disabled');
			$carouselControl.filter('.left').find('.block-table-cell').text(
				$this.find('.item.active').siblings().last().data('caption')
			);
			$carouselControl.filter('.right').find('.block-table-cell').text(
				$this.find('.item.active').next().data('caption')
			);
			$thisIndicators.removeClass('disabled');
		}
	});

	$carousel.each(function (i) {
		var $this = $(this),
		thisId = $this.attr('id'),
		$thisId = '#' + thisId;
		$($thisId).carousel({
			interval : 0
		});

		var caption = $($thisId + ' .item:nth-child(1) .carousel-caption');

		$($thisId + ' + .bottom-carousel-caption').html(caption.html());

		$($thisId).on('slide.bs.carousel', function (evt) {
			var caption = $($thisId + ' .item:nth-child(' + ($(evt.relatedTarget).index() + 1) + ') .carousel-caption');
			$($thisId + ' + .bottom-carousel-caption').html(caption.html());

		});
	});

	$carousel.swipe({
		swipeRight: function(event, direction, distance, duration, fingerCount) {
			$(event.currentTarget).carousel('prev');
		}, swipeLeft: function(event, direction, distance, duration, fingerCount) {
			$(event.currentTarget).carousel('next');
		}
	});

	$carousel.on('slide.bs.carousel', function(e) {

		var $this = $(this);
		var $target = $(e.relatedTarget);
		var $right = $this.find('.right .block-table-cell');
		var $left = $this.find('.left .block-table-cell');

		if ($target.next().length > 0) {
			$right.text( $target.next().data('caption') );
		} else {
			$right.text( $target.siblings().first().data('caption') );
		}
		if ($target.prev().length > 0) {
			$left.text( $target.prev().data('caption') );
		} else {
			$left.text( $target.siblings().last().data('caption') );
		}
	})

});