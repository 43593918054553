var $top = $('#top'),
$main = $('#main'),
$formModal = $('#form_modal');

function makeFixed() {
	$top.addClass('fixed-nav');
	$main.css('padding-top', $top.height());
}
function makeAffix() {
	$('#page_nav').affix({
		offset: {
			top: $('#page_header').height()
		}
	});
	// $(window).scroll(function() {
	// 	if( document.body.scrollTop > $('#page_header').height() ) {
	// 		$('#page_nav').addClass('affix').removeClass('affix-top');
	// 		console.log(true);
	// 	} else {
	// 		$('#page_nav').removeClass('affix').addClass('affix-top');
	// 		console.log(false);
	// 	}
	// });
	//console.log( $('#page_header').height() );
}
$(function() {
	var $nav = $(document.getElementById('page_nav'));
	var $page_header = $(document.getElementById('page_header'));
	$nav.find('a').on('click', function (e) {
		e.preventDefault();
		var menuHeight = $nav.height();
		var topMenuHeight = $page_header.height();
		var hash = $(e.currentTarget).attr('href');

		if($(hash).length > 0) {
			if( $(window).scrollTop() > topMenuHeight ) {
				$('html,body').animate({
					'scrollTop' : $(hash).offset().top - menuHeight + 5
				}, 500);
			}/* else if( $(hash).offset().top + 5 ) {

			}*/ else {
				$('html,body').animate({
					'scrollTop' : $(hash).offset().top - menuHeight - topMenuHeight + 5
				}, 500);
			}
		}
	});
	
	$formModal.on('show.bs.modal', function() {
		$(window).removeData('affix');
	});
	$formModal.on('hide.bs.modal', function() {
		makeAffix();
	});
	makeAffix();
	makeFixed();
})