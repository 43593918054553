$(function() {
	// Cache selectors
	var lastId,
	header = $('page_header'),
	topMenu = $("#page_nav"),
	headerHeight = header.outerHeight(),
	topMenuHeight = topMenu.outerHeight() + 15,
		// All list items
		menuItems = topMenu.find("a"),
		// Anchors corresponding to menu items
		scrollItems = menuItems.map(function() {
			var item = $($(this).attr("href"));
			if (item.length) {
				return item;
			}
		});

	// Bind click handler to menu items
	// so we can get a fancy scroll animation
	
	menuItems.click(function(e) {
		var href = $(this).attr("href"),
		offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
		e.preventDefault();
	});

	// Bind to scroll
	$(window).scroll(function() {

		// Get container scroll position
		var scrollTop = $(this).scrollTop();
		var fromTop = scrollTop + topMenuHeight + 10;
		var fromTop2 = fromTop + window.innerHeight / 4;

		// Get id of current scroll item
		var cur = scrollItems.map(function(current, i) {
			var offsetTop = $(this).offset().top;
			if (headerHeight + topMenuHeight < scrollTop && offsetTop <= fromTop2) {
				return this;
			}
		});
		// Get the id of the current element
		cur = cur[cur.length - 1];
		var id = cur && cur.length ? cur[0].id : "";
		if (lastId !== id) {
			lastId = id;
			menuItems
			.removeClass("active")
			.end();
			menuItems.filter("[href='#" + id + "']").addClass('active')

		}
	});
});