(function() {

	if (!Object.assign) {
		Object.defineProperty(Object, 'assign', {
			enumerable: false,
			configurable: true,
			writable: true,
			value: function(target, firstSource) {
				'use strict';
				if (target === undefined || target === null) {
					throw new TypeError('Cannot convert first argument to object');
				}

				var to = Object(target);
				for (var i = 1; i < arguments.length; i++) {
					var nextSource = arguments[i];
					if (nextSource === undefined || nextSource === null) {
						continue;
					}

					var keysArray = Object.keys(Object(nextSource));
					for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
						var nextKey = keysArray[nextIndex];
						var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
						if (desc !== undefined && desc.enumerable) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
				return to;
			}
		});
	}

	var lteie8 = !!(document.all && !document.addEventListener && navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/MSIE/));
	var repo = $("#repo .item");

	function initFeatureSlider(scrollbar) {
		$("#modal-carousel").carousel({
			interval: false,
			wrap: true
		});

		/* change modal title when slide changes */
		$("#modal-carousel").on("slid.bs.carousel", function() {
			$("#modal-switching .modal-header h2").html($(this).find(".active .title").html());
		})

		/* when clicking a thumbnail */
		$(".modal-description").click(function(e) {
			e.preventDefault();

			var content = $("#modal-carousel .carousel-inner");
			var title = $("#modal-switching .modal-header h2");

			content.empty();
			title.empty();

			var href = $(this).attr('href');
			var repoCopy = repo.clone(true);
			repo.remove();

			var active = repoCopy.filter(href).first();

			active.addClass("active");
			title.html(active.find(".title").html());
			repoCopy.find('.title').hide();
			content.append(repoCopy);

			if (scrollbar === true) $('.no-cssscrollbar').find('#modal-carousel .feature-description-modal-text').mCustomScrollbar();

			// show the modal
			$("#modal-switching").modal("show");
		});
	}

	// Menu shifting fix
	var fixedCls = '.page-navigation';
	var oldSSB = $.fn.modal.Constructor.prototype.setScrollbar;
	$.fn.modal.Constructor.prototype.setScrollbar = function() {
		oldSSB.apply(this);
		if (this.bodyIsOverflowing && this.scrollbarWidth)
			$(fixedCls).css('width', screen.width - this.scrollbarWidth);
	}

	var oldRSB = $.fn.modal.Constructor.prototype.resetScrollbar;
	$.fn.modal.Constructor.prototype.resetScrollbar = function() {
		oldRSB.apply(this);
		$(fixedCls).css('width', '');
	}

	$(window).resize(function() {
		if ($('body').hasClass('modal-open')) $(fixedCls).css('width', $('body').prop('clientWidth'));
	});

	$(document).ready(function() {

		if (!Modernizr.svg) {
			$('.functionality-item').each(function() {
				var $img = $(this).find('img'),
					src = $img.attr('src');
				$img.attr('src', src.replace(/\.svg$/, '.png'));
			});
		}

		if (!Modernizr.cssscrollbar) {

			$.when(
				$.getScript('./js/jquery.mousewheel.js'),
				$.getScript('./js/jquery.mCustomScrollbar.js'),
				$.Deferred(function(deferred) {
					$(deferred.resolve);
				})
			).done(function() {
				$('#repo').removeClass('hidden');
				initFeatureSlider(true);

				$('#repo').addClass('hidden');
			});
		} else {
			initFeatureSlider();
		}


		if (!lteie8) {
			var s = skrollr.init({
				forceHeight: false,
				mobileCheck: function() {
					return false;
				}
			});
		}

		var $at = $('.advantages-text'),
			$slides = $at.find('.advantages-text-slide'),
			$ai = $('.advantages-item');
		$slides.first().addClass('active');
		var slidesArr = [];
		$slides.each(function(i) {
			$(this).show();
			slidesArr[i] = $(this).height();
			$(this).not('.active').hide();
		});
		console.log(slidesArr);
		$at.css('height', Math.max.apply(Math, slidesArr));
		$ai.on('mouseover click', function(e) {
			var selector = $(this).data('target');
			$slides.removeClass('active');
			$(selector).addClass('active');
			if (screen.width < 800 && e.type === 'click') {
				$('html,body').animate({
					'scrollTop': $('#advantages').offset().top
				}, 400);
			}
		});

	});

	$(document).on('shown.bs.modal hidden.bs.modal', function(event) {
		if ($('.modal:visible').length) {
			$('body').addClass('modal-open');
		}
	});


	// Найти контрагента

	function getSearchInitialData() {
		return {
			orgSearch: '',
			innSearch: '',
			kppSearch: '',
			exact: true,
			warning: 'Чтобы продолжить, заполните хотя бы одно поле',
			res: [],
			submitToggle: false,
			visible: false
		}
	}

	try {
		var search = new Vue({
			el: '#contragSearch',
			data: function() {
				return getSearchInitialData();
			},
			mounted: function() {
				$(this.$refs.vuemodal).on("hidden.bs.modal", this.reset);
			},
			computed: {
				isValidINN: function() {
					return /^(\d{10}|\d{12})$/.test(this.innSearch);
				},
				isValidKPP: function() {
					return /^\d{9}$/.test(this.kppSearch);
				},
				isEmpty: function() {
					return !this.orgSearch.trim() && !this.isValidINN && !this.isValidKPP
				}
			},
			methods: {
				submit: function() {
					this.submitToggle = this.submitToggle ? false : true;
					// this.visible = true;
				},
				reset: function() {
					Object.assign(this.$data, getSearchInitialData());
				}
			},
			watch: {
				submitToggle: function() {
					if(this.isEmpty) return;
					this.response = axios.get('http://10.0.0.166/controller/searchcontrag', {
						params: {
							name: this.orgSearch,
							inn: this.innSearch,
							kpp: this.kppSearch,
							exact: this.exact
						}
					}).then(function(response) {
						if (typeof response.data === 'object' && !!response.data.contrag) {
							search.$data.res = response.data.contrag;
						} else {
							search.$data.res = [];
						}
						setTimeout(function() {
							search.$data.visible = true;
						}, 50);
					});
				}
			}
		});
	} catch (error) {
		console.error(error);
	}

})();