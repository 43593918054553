(function() {
	yaMapsShown = false; 

	function showYaMaps(){
		$.getScript("//api-maps.yandex.ru/2.1/?lang=ru_RU", function() {
			ymaps.ready(init);
			$(window).resize();
		});
	}

	var mapCenter = [55.772102, 37.665232];
	var point = [55.772102, 37.650675];

	function init() {     
		var map = new ymaps.Map("map", {
			center: (screen.width < 768 ? point : mapCenter),
			zoom: 15,
			controls: []
		});

		var placeMark = new ymaps.Placemark(point, { 
				}, {
					iconLayout: 'default#image',
					iconImageClipRect: [[9,7], [55, 65]],
					iconImageHref: 'pic/icons.png',
					iconImageSize: [45, 58],
					iconImageOffset: [-15, -27],
				}
			);

		map.geoObjects.add(placeMark);
		map.behaviors.disable('scrollZoom');

		map.controls.add("zoomControl", {
			position: {top: 65, left: 15}
		});
		map.controls.add("fullscreenControl", {
			position: {top: 20, left: 15}
		});

		$(window).on('resize', function() {
			if (screen.width < 768) {
				map.setCenter(point);
			} else {
				map.setCenter(mapCenter);
			}
		});
	};

	$(document).ready(function() {
		$(window).scroll(function() {
			if (!yaMapsShown){
				if($(window).scrollTop() + $(window).height() > $(document).height() / 2) {      
					showYaMaps();
					yaMapsShown = true;
				}
			}
		});
	});

})();